<template>
    <div>
        <div class="search-box">
            <el-form ref="form" class="horizontal-form" label-position="top">
                <el-form-item>
                    <el-input v-model="searchQuery" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button @click="searchVideos" type="primary">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button @click="getCacheList" type="primary">缓存列表</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="video-list" v-if="!cacheListVisible">
            Search Result :
            <el-table :data="videoList">
                <el-table-column label="Actions">
                    <template slot-scope="scope">
                        <el-button v-if="!scope.row.file_name" @click="cache(scope.row)" size="small">cache</el-button>
                        <el-button v-if="scope.row.file_name" @click="preview(scope.row.file_name)"
                            size="small">preview</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="title" prop="title" width="700"></el-table-column>
                <el-table-column label="ownerText" prop="owner_text"></el-table-column>
                <el-table-column label="duration" prop="duration"></el-table-column>
                <el-table-column label="publishedTimeText" prop="published_time_text"></el-table-column>
            </el-table>
        </div>
        <div class="cache-list" v-if="cacheListVisible">
            Cache List :
            <el-table :data="cacheList">
                <el-table-column label="Actions">
                    <template slot-scope="scope">
                        <el-button @click="preview(scope.row.file_name)" size="small">preview</el-button>
                        <el-button @click="del(scope.row.id)" size="small">delete</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="title" prop="title" width="700"></el-table-column>
                <el-table-column label="ownerText" prop="owner_text"></el-table-column>
                <el-table-column label="duration" prop="duration"></el-table-column>
                <el-table-column label="publishedTimeText" prop="published_time_text"></el-table-column>
            </el-table>
        </div>
    </div>
</template>
  
<script>
import { get, getRestful, post } from '../../api'

export default {
    name: 'Youtube',
    data() {
        return {
            searchQuery: '',
            videoList: [],
            cacheList: [],
            cacheListVisible: false,
            websitePrefix: 'http://taocoweb.top/static/youtube/'
        };
    },
    methods: {
        searchVideos() {
            if (!this.searchQuery) {
                this.$message.error('搜索内容不能为空');
                return;
            }
            getRestful('/yt/search/video', this.searchQuery).then(res => {
                this.videoList = res.data
                this.cacheListVisible = false
            })
        },
        cache(video) {
            post('/yt/cache', video).then(() => {
                this.$message.success('开始进行缓存！');
            })
        },
        getCacheList() {
            get('/yt/cache/list').then(res => {
                this.cacheList = res.data
                this.cacheListVisible = true
            })
        },
        preview(file_name) {
            window.open(this.websitePrefix + file_name, "_blank");
        },
        del(id) {
            this.$confirm('Are you sure you want to delete the selected items?', 'Warning', {
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                type: 'warning'
            })
                .then(() => {
                    getRestful('/yt/cache/del', id).then(res => {
                        if (res.success) {
                            this.$message.success('删除完毕！');
                            this.getCacheList()
                        }
                    })
                })
                .catch(() => {
                    this.$message.info('Delete canceled');
                });
        }
    },
};
</script>
  
<style scoped>
.search-box {
    margin-top: 3%;
    margin-left: 35%;
}

.horizontal-form .el-form-item {
    display: inline-block;
    width: 30%;
}
</style>
