<template>
    <div>
        <FullCalendar class="calendar" :options="calendarOptions" />
        <el-dialog title="Edit Event" :visible.sync="showEditDialog" @close="closeEditDialog">
            <el-form :model="eventForm" ref="eventForm" :rules="eventRules">
                <el-form-item label="Date">
                    <el-date-picker v-model="eventForm.start" type="datetime" placeholder="Pick Time"
                        value-format="yyyy-MM-dd HH:mm:ss">
                    </el-date-picker>
                    -
                    <el-date-picker v-model="eventForm.end" type="datetime" placeholder="Pick Time"
                        value-format="yyyy-MM-dd HH:mm:ss">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="Category" prop="cate">
                    <el-select v-model="eventForm.cate" placeholder="Select">
                        <el-option v-for="item in cate" :key="item" :label="item.label" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Title" prop="title">
                    <el-input v-model="eventForm.title"></el-input>
                </el-form-item>
                <el-form-item label="Memo" prop="memo">
                    <v-md-editor v-model="eventForm.memo" mode="edit" height="400px"></v-md-editor>
                </el-form-item>
                <el-form-item label="URL" prop="url">
                    <el-input v-model="eventForm.url" type="textarea" autosize></el-input>
                </el-form-item>
                <el-form-item label="Remind" prop="remind">
                    <el-switch v-model="eventForm.remind"></el-switch>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitEvent">Submit</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="Event Detail" :visible.sync="showDetailDialog" @close="closeDetailDialog">
            <el-form :model="eventForm" ref="eventForm" :rules="eventRules">
                <el-form-item label="Date">
                    <span>{{ eventForm.start }}</span> —
                    <span>{{ eventForm.end }}</span>
                </el-form-item>
                <el-form-item label="Category" prop="cate">
                    <span>{{ eventForm.cate }}</span>
                </el-form-item>
                <el-form-item label="Title" prop="title">
                    <span>{{ eventForm.title }}</span>
                </el-form-item>
                <el-form-item label="Memo" prop="memo">
                    <vue-markdown :source="eventForm.memo"></vue-markdown>
                </el-form-item>
                <el-form-item v-show="eventForm.url" label="URL" prop="url">
                    <a :href="eventForm.url">open link</a>
                </el-form-item>
                <el-form-item label="Remind" prop="remind">
                    <el-switch v-model="eventForm.remind"></el-switch>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="eventEdit">Edit</el-button>
                    <el-button type="primary" @click="eventDelete">Delete</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import VueMarkdown from 'vue-markdown';

import { post, get, getRestful } from '../../api'
import { dailyMemoTemp, unLostMemoTemp } from '../../constant/reminderConstant';

export default {
    components: {
        FullCalendar,
        VueMarkdown
    },
    created() {
        this.loadEvents()
    },
    watch: {
        'eventForm.cate': function (newCate) {
            if (this.showEditDialog && this.eventForm.memo == '') {
                if (newCate === '日报') {
                    this.eventForm.title = this.today + "日报"
                    this.eventForm.memo = dailyMemoTemp
                } else if (newCate === '防迷失录') {
                    this.eventForm.title = this.today + "防迷失录"
                    this.eventForm.memo = unLostMemoTemp
                } else {
                    this.eventForm.title = ''
                    this.eventForm.memo = ''
                }
            }
        },
        'eventForm.start': function (newStart) {
            if (Math.abs(new Date(newStart) - new Date(this.eventForm.end)) >= 24 * 60 * 60 * 1000) {
                this.eventForm.allDay = true
            } else {
                this.eventForm.allDay = false
            }

            if (new Date() < new Date(newStart) && this.eventForm.allDay == false) {
                this.eventForm.remind = true
            }
        },
        'eventForm.end': function (newEnd) {
            if (Math.abs(new Date(this.eventForm.start) - new Date(newEnd)) >= 24 * 60 * 60 * 1000) {
                this.eventForm.allDay = true
            } else {
                this.eventForm.allDay = false
            }
        }
    },
    data() {
        return {
            calendarOptions: {
                plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
                initialView: 'dayGridMonth',
                headerToolbar: {
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay",
                },
                events: [],
                eventClick: this.eventClick,
                weekends: true,
                selectable: true,
                select: this.selectDates,
            },
            showEditDialog: false,
            showDetailDialog: false,
            eventForm: {
                title: '',
                memo: '',
                start: '',
                end: '',
                type: '',
                url: '',
                allDay: true,
                cate: '',
                remind: false
            },
            eventRules: {
                title: [{ required: true, message: 'plz input event title', trigger: 'blur' }],
                cate: [{ required: true, message: 'plz select event category', trigger: 'blur' }],
            },
            cate: [
                '日报',
                '防迷失录',
                '其他'
            ],
            today: '',
        }
    },
    methods: {
        loadEvents() {
            get('/reminder/get_events').then(res => {
                this.calendarOptions.events = res.data
            })
        },
        selectDates(arg) {
            this.eventForm.start = arg.start;
            this.eventForm.end = arg.end;

            const startYear = arg.start.getFullYear();
            const startMonth = String(arg.start.getMonth() + 1).padStart(2, '0');
            const startDay = String(arg.start.getDate()).padStart(2, '0');
            const startHours = String(arg.start.getHours()).padStart(2, '0');
            const startMinutes = String(arg.start.getMinutes()).padStart(2, '0');
            const startSeconds = String(arg.start.getSeconds()).padStart(2, '0');

            const endYear = arg.end.getFullYear();
            const endMonth = String(arg.end.getMonth() + 1).padStart(2, '0');
            const endDay = String(arg.end.getDate()).padStart(2, '0');
            const endHours = String(arg.end.getHours()).padStart(2, '0');
            const endMinutes = String(arg.end.getMinutes()).padStart(2, '0');
            const endSeconds = String(arg.end.getSeconds()).padStart(2, '0');

            this.eventForm.start = `${startYear}-${startMonth}-${startDay} ${startHours}:${startMinutes}:${startSeconds}`;
            this.eventForm.end = `${endYear}-${endMonth}-${endDay} ${endHours}:${endMinutes}:${endSeconds}`;
            this.today = `${startYear}-${startMonth}-${startDay}`
            // this.eventForm.allDay = arg.allDay;
            this.eventForm.type = arg.view.type;
            this.showEditDialog = true;
        },
        closeEditDialog() {
            this.showEditDialog = false;
            this.eventForm = {
                title: '',
                memo: '',
                start: '',
                end: '',
                type: '',
                url: '',
                allDay: true,
                cate: '',
                remind: false
            }
        },
        closeDetailDialog() {
            this.showDetailDialog = false;
            this.eventForm = {
                title: '',
                memo: '',
                start: '',
                end: '',
                type: '',
                url: '',
                allDay: true,
                cate: '',
                remind: false
            }
        },
        submitEvent() {
            this.$refs.eventForm.validate(async (valid) => {
                if (valid) {
                    post('/reminder/save_event', this.eventForm).then(() => {
                        this.loadEvents()
                        this.closeEditDialog();
                        this.$message.success('Save successfully');
                    })
                }
            });
        },
        eventClick(info) {
            if (info.event.url) {
                info.jsEvent.preventDefault(); // don't let the browser navigate
                window.open('/user/dashboard/vocabulary/' + info.event.url);
            } else {
                getRestful('/reminder/get_event', info.event.id).then((res) => {
                    this.eventForm = res.data
                    this.showDetailDialog = true
                })
            }
        },
        eventEdit() {
            this.showDetailDialog = false
            getRestful('/reminder/get_event', this.eventForm.id).then((res) => {
                this.eventForm = res.data
                this.showEditDialog = true
            })
        },
        eventDelete() {
            this.$confirm('Are you sure you want to delete the selected items?', 'Warning', {
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                type: 'warning'
            })
                .then(() => {
                    getRestful('/reminder/del_event', this.eventForm.id).then(() => {
                        this.$message.success('Delete successfully');
                        this.closeDetailDialog();
                        this.loadEvents()
                    })
                })
                .catch(() => {
                    this.$message.info('Delete canceled');
                });
        }
    }
}
</script>

<style></style>