<template>
  <div>
    <el-upload :action="uploadUrl" :on-success="handleUploadSuccess" :show-file-list="false" :headers="headerObj"
               multiple>
      <el-button type="primary">上传图片</el-button>
    </el-upload>
    <el-table :data="imageList" style="width: 100%">
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button @click="downloadImage(scope.row)">下载</el-button>
          <el-button @click="deleteImage(scope.row.filename)">删除</el-button>
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="filename"/>
      <el-table-column label="图片">
        <template slot-scope="scope">
          <img :src="scope.row.url" alt="Image" style="max-width: 10%">
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {post} from '@/api'
import FileSaver from 'file-saver';

export default {
  name: 'ImageGallery',
  data() {
    return {
      imageList: [],
      headerObj: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
  },
  computed: {
    uploadUrl() {
      return 'http://taocoweb.top/api/file/upload';
    },
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      post('/file/list', {'file_type': 'image'}).then(res => {
        this.imageList = res.data;
      })
    },
    handleUploadSuccess(response) {
      if (response.success) {
        this.imageList.push(response.data);
      } else {
        this.$message.error('上传失败');
      }
    },
    downloadImage(file) {
      fetch(file.url)
          .then(response => response.blob())
          .then(blob => {
            FileSaver.saveAs(blob, file.filename);
          })
          .catch(() => {
            this.$message.error('下载失败');
          });
    },
    deleteImage(image_name) {
      this.$confirm('Are you sure you want to delete the selected items?', 'Warning', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
          .then(() => {
            post('/file/delete', {'filename': image_name}).then(res => {
              if (res.success) {
                this.$message.success('Deleted!');
                this.getList()
              }
            })
          })
          .catch(() => {
            this.$message.info('Delete canceled');
          });
    }
  },
};
</script>

<style></style>
