<template>
    <div>
        <el-button @click="updateNews('reuters')">Update Reuters</el-button>
        <el-button @click="updateNews('bloomberg')">Update Bloomberg</el-button>

        <el-form ref="form" :model="form" class="horizontal-form" label-position="top">
            <el-form-item label="source_name">
                <el-select v-model="form.source_name" placeholder="请选择">
                    <el-option v-for="item in source_names" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Into DB Time">
                <el-date-picker v-model="form.into_db_time" value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="Limit">
                <el-input v-model="form.limit"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSearch">Search</el-button>
            </el-form-item>
        </el-form>

        <el-table v-loading="loading" v-if="tableVisible" :data="tableData" style="width: 100%">
            <el-table-column prop="title" label="title">
                <template slot-scope="scope">
                    <router-link :to="`/news/${scope.row.id}`" target="_blank">{{ scope.row.title }}</router-link>
                </template>
            </el-table-column>
            <el-table-column prop="into_db_time" label="title" />
        </el-table>
    </div>
</template>

<script>
import { getRestful, post } from '../../api'

export default {
    name: "NewsList",
    data() {
        return {
            form: {
                source_name: 'bloomberg',
                into_db_time: '',
                limit: 1000
            },
            source_names: [{
                value: 'bloomberg',
                label: 'bloomberg'
            }, {
                value: 'reuters',
                label: 'reuters'
            }],
            tableData: [],
            loading: false,
            tableVisible: false
        }
    },
    mounted() {
        this.formatTodayDate();
    },
    methods: {
        updateNews(source_name) {
            getRestful('/news/update', source_name)
        },
        formatTodayDate() {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0')
            this.form.into_db_time = `${year}-${month}-${day}`;
        },
        onSearch() {
            this.tableVisible = true
            this.loading = true
            post('/news/search', this.form).then(res => {
                this.tableData = res.data
                this.loading = false
            })
        }
    }
}
</script>

<style>
.horizontal-form .el-form-item {
    display: inline-block;
    width: 200px;
    /* 设置表单项的宽度，根据需要调整 */
    margin-right: 20px;
    /* 设置表单项之间的间距，根据需要调整 */
}
</style>