<template>
    <el-dialog title="Add New Word" :visible.sync="dialogVisible" @close="close">
        <el-form :model="wordForm" ref="wordForm" label-width="100px">
            <el-form-item label="Action">
                <el-button type="primary" @click="saveWord">Save</el-button>
                <el-button @click="resetForm" v-if="!wordForm.id">Reset</el-button>
            </el-form-item>
            <el-form-item label="Images">
                <el-input v-model="wordForm.image" clearable placeholder="Enter URL" style="max-width: 100px;"></el-input>
                <div>
                    <img v-show="wordForm.image" :src="wordForm.image" width="50px">
                </div>
            </el-form-item>
            <el-form-item label="Word">
                <el-input v-model="wordForm.word" placeholder="Enter the word" style="max-width: 160px;"></el-input>
                <el-button @click="dictWord('cambridge')" type="text" icon="el-icon-search" round>Cambridge</el-button>
                <el-button @click="dictWord('google')" type="text" icon="el-icon-search" round>Google</el-button>
            </el-form-item>
            <el-form-item label="My Example">
                <div class="block" v-for="(myExample, index) in wordForm.my_examples" :key="index">
                    <el-form-item label="En">
                        <el-input v-model="myExample.en" type="textarea" autosize
                            placeholder="Enter the Sentence"></el-input>
                    </el-form-item>
                    <el-form-item label="Zh">
                        <el-input v-model="myExample.zh" type="textarea" autosize placeholder="Enter Translation"></el-input>
                    </el-form-item>
                    <el-button type="text" round icon="el-icon-minus" @click="removeDiv(wordForm.my_examples, index)"
                        v-show="wordForm.my_examples.length > 1"></el-button>
                </div>
                <el-button type="text" round icon="el-icon-plus" @click="addDiv(wordForm.my_examples)"></el-button>
            </el-form-item>
            <el-form-item label="Explain">
                <div class="block" v-for="(explanation, index) in wordForm.explanations" :key="index">
                    <el-form-item label="Type">
                        <el-input v-model="explanation.type" placeholder="Enter the Type"></el-input>
                    </el-form-item>
                    <el-form-item label="explanation">
                        <el-input v-model="explanation.explanation_info" type="textarea" autosize
                            placeholder="Enter the Explanation"></el-input>
                    </el-form-item>
                    <el-form-item label="Extend_info">
                        <el-input v-model="explanation.extend_info" placeholder="Enter the Extend_info"></el-input>
                    </el-form-item>
                    <el-form-item label="ZhMeaning">
                        <el-input v-model="explanation.zh_explanation_info" autosize show-password
                            placeholder="Enter the ZhMeaning"></el-input>
                    </el-form-item>
                    <el-form-item label="Examples">
                        <div class="block" v-for="(examp, index) in explanation.examps" :key="index">
                            <el-form-item label="En">
                                <el-input v-model="examp.en" type="textarea" autosize
                                    placeholder="Enter the Sentence"></el-input>
                            </el-form-item>
                            <el-form-item label="Zh">
                                <el-input v-model="examp.zh" show-password autosize
                                    placeholder="Enter Translation"></el-input>
                            </el-form-item>
                            <el-button type="text" round icon="el-icon-minus" @click="removeDiv(explanation.examps, index)"
                                v-show="explanation.examps.length > 1"></el-button>
                        </div>
                        <el-button type="text" round icon="el-icon-plus" @click="addDiv(explanation.examps)"></el-button>
                    </el-form-item>
                    <el-button type="text" round icon="el-icon-minus" @click="removeDiv(wordForm.explanations, index)"
                        v-show="wordForm.explanations.length > 1"></el-button>
                </div>
                <el-button type="text" round icon="el-icon-plus" @click="addDiv(wordForm.explanations)"></el-button>
            </el-form-item>
            <el-form-item label="Sound">
                <div class="block" v-for="(sound, index) in wordForm.sounds" :key="index">
                    <el-form-item label="Region">
                        <el-input v-model="sound.region" :disabled="true" placeholder="Enter the Region"></el-input>
                    </el-form-item>
                    <el-form-item label="Mark">
                        <el-input v-model="sound.sound_mark" :disabled="true" placeholder="Enter the Mark"></el-input>
                    </el-form-item>
                    <el-form-item label="Mark Mp3">
                        <el-input v-model="sound.sound_mark_mp3" :disabled="true"
                            placeholder="Enter the Mark Mp3"></el-input>
                    </el-form-item>
                </div>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
  
<script>
import { post } from '../../api'

export default {
    props: {
        dialogVisible: Boolean
    },
    data() {
        return {
            wordForm: {
                word: '',
                image: '',
                word_type: '',
                sounds: [],
                explanations: [
                    { type: '', explanation_info: '', extend_info: '', zh_explanation_info: '', examps: [{ en: '', zh: '' }] }
                ],
                my_examples: [
                    { en: '', zh: '' }
                ]
            },
            editingWordId: null
        };
    },
    methods: {
        saveWord() {
            if (this.wordForm.my_examples[0].en === '') {
                this.$message.error('My Examples cannot be empty !');
                return
            }
            if (this.editingWordId !== null) {
                this.wordForm.id = this.editingWordId
                post('/vocabulary/update', this.wordForm).then(() => {
                    this.$message.success('Update Successfully !');
                    this.close()
                })
            } else {
                post('/vocabulary/save', this.wordForm).then(() => {
                    this.$message.success('Add Successfully !');
                    this.close()
                })
            }
        },
        resetForm() {
            this.wordForm = {
                word: '',
                image: '',
                word_type: '',
                sounds: [],
                explanations: [
                    { type: '', explanation_info: '', extend_info: '', zh_explanation_info: '', examps: [{ en: '', zh: '' }] }
                ],
                my_examples: [
                    { en: '', zh: '' }
                ]
            },
                this.editingWordId = null;
            this.$refs.wordForm.resetFields();
        },
        close() {
            this.$parent.loadWordList()
            this.$parent.dialogVisible = false;
            this.resetForm()
        },
        dictWord(source) {
            if (this.wordForm.word == "") {
                this.$message.error('Word Needed !');
                return
            }
            post('/vocabulary/dictionary', { "word": this.wordForm.word, "source": source }).then(res => {
                this.wordForm.word = res.data.word
                this.wordForm.word_type = res.data.word_type
                this.wordForm.sounds = res.data.sounds
                this.wordForm.explanations = res.data.explanations
            })
        },
        addDiv(list) {
            list.push({ en: '', zh: '' });
        },
        removeDiv(list, index) {
            if (list.length > 1) {
                list.splice(index, 1);
            }
        }
    }
};
</script>
  
<style scoped>
.block {
    border: 1px solid #DCDCDC
}
</style>
