<template>
  <div class="lyrics-container" ref="lyricsContainer">
    <div class="lyrics">
      <p v-for="(line, index) in lyrics" :key="index" :class="{ active: index === currentLine }" ref="lyricsLine">
        {{ line }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lyrics: [
        "Line 1: Lorem ipsum dolor sit amet",
        "Line 2: Consectetur adipiscing elit",
        "Line 3: Sed do eiusmod tempor incididunt",
        "Line 4: Ut labore et dolore magna aliqua",
        "Line 5: Ut enim ad minim veniam",
        "Line 6: Quis nostrud exercitation ullamco",
        "Line 7: Laboris nisi ut aliquip ex ea commodo consequat"
      ],
      currentLine: 0
    };
  },
  mounted() {
    this.scrollLyrics();
  },
  methods: {
    scrollLyrics() {
      setInterval(() => {
        this.currentLine = (this.currentLine + 1) % this.lyrics.length;
        this.adjustScroll();
      }, 2000); // 每2秒滚动一次歌词
    },
    adjustScroll() {
      // 获取歌词容器和当前歌词元素
      const container = this.$refs.lyricsContainer;
      const activeLine = this.$refs.lyricsLine[this.currentLine];

      // 计算容器高度和当前歌词高度，调整滚动位置
      const containerHeight = container.clientHeight;
      const lineHeight = activeLine.clientHeight;
      const offsetTop = activeLine.offsetTop;

      // 滚动使得当前歌词在容器的垂直中间
      container.scrollTop = offsetTop - (containerHeight / 2) + (lineHeight / 2);
    }
  }
};
</script>

<style scoped>
.lyrics-container {
  width: 400px;
  height: 200px;
  overflow: hidden;
  position: relative;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  margin: 0 auto;
  overflow-y: auto; /* 允许纵向滚动 */
}

.lyrics {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

p {
  font-size: 1.2em;
  margin: 0;
  padding: 10px 0;
  color: #333;
  opacity: 0.6;
  transition: opacity 0.5s ease;
}

p.active {
  font-weight: bold;
  color: #000;
  opacity: 1;
}
</style>