<template>
    <footer>
        <p>Copyright © 2023 Mark Chan's Blog</p>
    </footer>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style></style>