<template>
  <div class="no-source">
    Oops! That page doesn't exist.
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style>
.no-source {
  font-family: 'Pacifico', cursive; /* 引入Pacifico字体，你可以换成其他字体 */
  font-size: 24px;
  margin-top: 20px;
  color: rgb(43, 109, 216);
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  animation: shake 0.5s infinite;
}
</style>