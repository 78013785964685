export const dailyMemoTemp = 
`
今日工作内容：
- .


今日心情：
- .


遇到的问题：
- .


明日计划：
- .

其他事项：
- .
`



export const unLostMemoTemp = 
`
防迷失问答：
- [问题1]：你昨天晚上在干嘛？昨天白天在干嘛？
- [答]：

- [问题2]：你最近在干什么呀？
- [答]：

- [问题3]：你最近是否经常感到无聊、焦虑或者沮丧？
- [答]：

- [问题4]：你最近是否经常做一些让你感到快乐和有成就感的事情？
- [答]：

- [问题5]：你最近是否有一些你想要改变或者提升的方面，让你感到有挑战和进步？
- [答]：

- [问题6]：你最近是否有一些你热爱并且尊重的价值观，让你感到有信念和方向？
- [答]：

- [问题7]：你最近是否有一些你感激并且享受的事物，让你感到有幸福和满足？
- [答]：

- [问题8]：你最近是否有一些你期待并且憧憬的事情，让你感到有希望和梦想？
- [答]：

- [问题9]：“你是一个彻头彻尾的废物垃圾！”，你发自内心认可这句话吗？
- [答]：

- [问题10]：最近有什么目标吗？（短期/长期）
- [答]：

- [问题11]：还记得你想要什么吗？记忆中的主线任务是什么？
- [答]：

- [问题12]：你最近有犯错吗？有的话，是什么错？
- [答]：

- [问题13]：你最近有做成哪件事吗？有的话，是什么事？请具体描述。
- [答]：

- [问题14]：最近如果有做成某件事，那么你对自己有奖励吗？
- [答]：

- [问题15]：你最近爱你自己吗？请写出这段时间你爱你自己的表现。
- [答]：

- [问题16]：命运能否被改变？
- [答]：
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
>**当你对其中的任何问题，在此时此刻，无法知道答案时。说明你开始或已经迷失了！
    请接受自己的迷失，你需要休息与沉淀。❤**
`