<template>
    <div></div>
</template>

<script>
import { get } from '../../api'
export default {
    name: "Stock",
    data() {
        return {
            watchList: []
        }
    },
    created() {
        // this.test()
    },
    methods: {
        myWatchList() {
            get('/stock/watch/list').then(res => {
                this.watchList = res.data
            })
        },
        test() {
            get('/stock/update').then(() => {

            })
        }
    }
}
</script>

<style></style>