<template>
    <div>
        <div class="search-box">
            <el-form ref="form" class="horizontal-form" label-position="top">
                <el-form-item>
                    <el-input v-model="searchQuery" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button @click="searchWords" type="primary">Search</el-button>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="my_example" clearable></el-input>
                </el-form-item>
            </el-form>
        </div>
        <el-button type="primary" @click="showDialog">Add New Word</el-button>
        <el-button @click="loadWordList">Refresh List</el-button>
        <el-button v-if="multipleSelection.length > 0" @click="handleDelete" type="danger">
            Delete Selected
        </el-button>
        <el-table v-loading="loading" :data="wordList" style="margin-top: 20px" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="70"></el-table-column>
            <el-table-column label="Act" width="100">
                <template slot-scope="scope">
                    <el-button type="text" icon="el-icon-edit" @click="editWord(scope.row)" />
                    <el-button type="text" icon="el-icon-tickets" @click="example('My Examples', scope.row.my_examples)" />
                </template>
            </el-table-column>
            <el-table-column label="Word" prop="word" width="150"></el-table-column>
            <el-table-column label="Image" prop="image" width="200">
                <template slot-scope="scope">
                    <img v-show="scope.row.image" :src="scope.row.image" width="150px">
                </template>
            </el-table-column>
            <el-table-column label="Sound" prop="sounds" width="170">
                <template slot-scope="scope">
                    <div v-for="(sound, index) in scope.row.sounds" :key="index">
                        {{ sound.region }} :
                        <span v-show="sound.showSound">
                            {{ sound.sound_mark }}
                        </span>
                        <el-button type="text" icon="el-icon-service" @click="playAudio(scope.row.id, index)"></el-button>
                        <el-button type="text" icon="el-icon-view" @click="toggleSoundVisibility(sound)" />
                        <audio :ref="`audioPlayer_${scope.row.id}_${index}`" :src="sound.sound_mark_mp3"></audio>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="Explanation" prop="explanations">
                <template slot-scope="scope">
                    <div v-for="(explanation, index) in scope.row.explanations" :key="index">
                        {{ explanation.type }}.
                        {{ explanation.extend_info }}
                        {{ explanation.explanation_info }}
                        <!-- 使用 v-show 来控制 zh_explanation_info 的可见性 -->
                        <span v-show="explanation.showZhExplanation">
                            {{ explanation.zh_explanation_info }}
                        </span>
                        <el-button type="text" icon="el-icon-view" @click="toggleExplanationVisibility(explanation)" />
                        <el-button type="text" icon="el-icon-tickets"
                            @click="example(explanation.explanation_info, explanation.examps, index)" />
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <VocaFormDialog :dialogVisible="dialogVisible" @close="closeFormDialog" ref="vocaFormDialog" />
        <VocaSentenceDialog ref="vocaSentenceDialog" />
    </div>
</template>
  
  
<script>
import { post, getRestful } from '../../api'
import VocaFormDialog from './VocaFormDialog.vue';
import VocaSentenceDialog from './VocaSentenceDialog.vue';

export default {
    components: {
        VocaFormDialog,
        VocaSentenceDialog
    },
    data() {
        return {
            dialogVisible: false,
            loading: true,
            searchQuery: '',
            multipleSelection: [],
            wordList: [],
            into_db_time: {},
            my_example: '',
        };
    },
    created() {
        this.into_db_time = this.$route.params.date
        this.loadWordList()
    },
    methods: {
        toggleExplanationVisibility(explanation) {
            explanation.showZhExplanation = !explanation.showZhExplanation
        },
        toggleSoundVisibility(sound) {
            sound.showSound = !sound.showSound
        },
        searchWords() {
            if (!this.searchQuery) {
                this.$message.error('搜索内容不能为空');
                return;
            }
            this.loading = true
            getRestful('/vocabulary/search', this.searchQuery).then(res => {
                this.loading = false
                if (res.data.length !== 0) {
                    this.wordList = res.data
                    return
                }
                this.$message.warning('The query result is empty!');
                this.$confirm('Do you wang to search from The Dictionary?', 'Warning', {
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.showDialog()
                    this.$refs.vocaFormDialog.wordForm.word = this.searchQuery
                    this.$refs.vocaFormDialog.wordForm.my_examples = [
                        { en: this.my_example, zh: '' }
                    ]
                    this.$refs.vocaFormDialog.dictWord("cambridge")
                }).catch(() => {
                    this.$message.info('Canceled');
                })
            })
        },
        playAudio(rowId, index) {
            const audio = this.$refs[`audioPlayer_${rowId}_${index}`][0];
            if (audio) {
                audio.play();
            }
        },
        loadWordList() {
            this.loading = true
            let payload = {}
            if (this.into_db_time) {
                payload = { 'into_db_time': this.into_db_time }
            }
            post('/vocabulary/list', payload).then(res => {
                this.wordList = res.data
                this.loading = false
            })
        },
        example(explanation_info, examps, index) {
            this.$refs.vocaSentenceDialog.open(explanation_info, examps, index);
        },
        showDialog() {
            this.dialogVisible = true;
        },
        closeFormDialog() {
            this.dialogVisible = false;
        },
        editWord(word) {
            if (word.my_examples === null) {
                word.my_examples = [
                    { en: '', zh: '' }
                ]
            }
            this.dialogVisible = true
            this.$refs.vocaFormDialog.editingWordId = word.id;
            this.$refs.vocaFormDialog.wordForm = { ...word };
        },
        handleSelectionChange(selection) {
            this.multipleSelection = selection;
        },
        handleDelete() {
            this.$confirm('Are you sure you want to delete the selected items?', 'Warning', {
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                type: 'warning'
            })
                .then(() => {
                    const selectedIds = this.multipleSelection.map(item => item.id);
                    post('/vocabulary/delete', selectedIds)
                        .then(() => {
                            this.$message.success('Delete successful!');
                            this.loadWordList();
                        })
                        .catch(() => {
                            this.$message.error('Delete failed!');
                        });
                })
                .catch(() => {
                    this.$message.info('Delete canceled');
                });
        }
    }
};
</script>
  
<style scoped>
.search-box {
    margin-top: 3%;
    margin-left: 35%;
}

.horizontal-form .el-form-item {
    display: inline-block;
    width: 30%;
}
</style>
