<template>
  <div>
<!--    <div class="search-box">-->
<!--      <el-button @click="updateSourceList" type="primary">update voa</el-button>-->
<!--      <el-button @click="getVoaSourceList" type="primary">flash list</el-button>-->
<!--    </div>-->
    <div class="video-list">
<!--      Source List :-->
      <el-table v-loading="loading"
                :data="paginatedData"
                @row-click="handleRowClick">
        <el-table-column label="Title" prop="title"/>
        <el-table-column
            label="Create Time"
            prop="into_db_time"
            :formatter="formatDate"
            min-width="30%"/>
      </el-table>
      <div class="pagination-container">
        <el-pagination
            background
            layout="prev, pager, next"
            :total="voaList.length"
            @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {get} from '../api'

export default {
  name: 'VoaLearnEnglish',
  data() {
    return {
      loading: true,
      voaList: [],
      currentPage: 1,
      pageSize: 10, // 每页显示的条目数
      websitePrefix: 'http://taocoweb.top/static/youtube/'
    };
  },
  computed: {
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.voaList.slice(start, end);
    }
  },
  created() {
    this.getVoaSourceList()
  },
  methods: {
    handleRowClick(row) {
      window.open('/voa/' + row.id, '_blank')
    },
    formatDate(row, column, cellValue) {
      var dt = new Date(cellValue);
      let year = dt.getFullYear();
      let month = (dt.getMonth() + 1).toString().padStart(2, '0');
      let date = dt.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${date}`;
    },
    getVoaSourceList() {
      this.loading = true,
          get('/voa/list').then(res => {
            this.loading = false
            this.voaList = res.data
          })
    },
    updateSourceList() {
      get('/voa/update').then(() => {
        this.$message.success('开始更新VOA资源');
      })
    },
    handleCurrentChange(page) {
      this.currentPage = page;
    }
  },
};
</script>

<style>
.search-box {
  margin-top: 3%;
  margin-left: 1%;
}

.horizontal-form .el-form-item {
  display: inline-block;
  width: 30%;
}

.pagination-container {
  display: flex;
  justify-content: center; /* 居中对齐 */
  margin-top: 20px; /* 可选，增加与表格的间距 */
}
</style>
