<template>
    <div>
        <div id="book"></div>
        <!-- <button @click="prevPage">Previous Page</button>
        <button @click="nextPage">Next Page</button> -->
    </div>
</template>
  
<script>
import Epub from "epubjs";

export default {
    data() {
        return {
            BOOK: null, // Store the BOOK instance in the component data
            rendition: null, // Store the Rendition instance in the component data
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            // Initialize the BOOK instance
            this.BOOK = new Epub("https://img1.yunser.com/epub/test.epub");

            // Generate Rendition object
            this.rendition = this.BOOK.renderTo("book", {
                manager: "continuous",
                snap: true,
            });

            // Render the initial display
            this.rendition.display();
        },
        nextPage() {
            // Go to the next page
            this.rendition.next();
        },
        prevPage() {
            // Go to the previous page
            this.rendition.prev();
        },
    },
};
</script>
  
<style scoped></style>
  