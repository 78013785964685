<template>
    <router-view></router-view>
</template>

<script>
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);
export default {
    name: 'Dashboard'
};
</script>

<style scoped></style>