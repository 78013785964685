<template>
    <div v-loading="loading" element-loading-text="拼命加载中">
        <div class="center-title">
            <h1>{{ article.title }}</h1>
        </div>
        <div class="info" v-show="article.into_db_time">
          <span>Source：{{ article.source_name }}</span>
          <span>Into DB Time：{{ article.into_db_time | formatDate }}</span>
          <hr>
        </div>
        <div v-html="article.html" class="center-content"></div>
    </div>
</template>

<script>
import { getRestful } from "../api";

export default {
    name: "newsDetail",
    created() {
        this.newsId = this.$route.params.newsId;
        getRestful("/news/detail", this.newsId).then((res) => {
            document.title = res.data.title
            this.article = res.data;
            this.loading = false;
        });
    },
    filters: {
      formatDate(value) {
        var dt = new Date(value);
        let year = dt.getFullYear();
        let month = (dt.getMonth() + 1).toString().padStart(2, '0');
        let date = dt.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${date}`;
      }
    },
    data() {
        return {
            newsId: "",
            article: {
                title: "",
                html: "",
            },
            loading: true,
        };
    },
};
</script>

<style scoped>
.center-title {
    text-align: center;
    /* 页面标题水平居中 */
}

.center-content {
    text-align: left;
    /* 文章内容向左对齐 */
    margin: 0 auto;
    /* 文章内容整体居中 */
    max-width: 800px;
    /* 如果需要，可以设置最大宽度以限制内容宽度 */
}

img {
  width: 50%;
  height: auto;
}
</style>