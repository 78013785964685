<template>
    <div class="schedule">
        <el-button type="primary" @click="showDialog">Add New Schedule</el-button>
        <el-button @click="reflush">Refresh List</el-button>
        <el-button @click="startScheduler">Start Scheduler</el-button>
        <el-button @click="stopScheduler">Stop Scheduler</el-button>
        <el-button v-if="multipleSelection.length > 0" @click="handleDelete" type="danger">
            Delete Selected
        </el-button>
        <div>Running: {{ running }}</div>
        <el-table v-loading="loading" :data="scheduleList" style="margin-top: 20px"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection"></el-table-column>
            <el-table-column label="Action">
                <template slot-scope="scope">
                    <el-button type="text" icon="el-icon-edit" @click="editSchedule(scope.row)" />
                    <el-button type="text" icon="el-icon-video-play" @click="executeTask(scope.row.id)" />
                </template>
            </el-table-column>
            <el-table-column prop="running" label="Running Health">
                <template slot-scope="scope">
                    <i v-if="scope.row.running == 1" class="el-icon-sunny"
                        style="color: green;font-size: 22px;font-weight: bold;"></i>
                    <i v-if="scope.row.running == 0" class="el-icon-moon"
                        style="color: grey;font-size: 22px;font-weight: bold;"></i>
                    <i v-if="scope.row.running == -1" class="el-icon-warning" style="color: red;font-size: 22px;"></i>
                </template>
            </el-table-column>
            <el-table-column prop="next_run_time" label="NT"></el-table-column>
            <el-table-column prop="name" label="Name"></el-table-column>
            <el-table-column prop="func_name" label="Function Name"></el-table-column>
            <el-table-column prop="trigger_type" label="Trigger Type"></el-table-column>
            <el-table-column prop="active" label="Active">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.active" :active-value="true" :inactive-value="false"></el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="email_flag" label="Email Flag">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.email_flag" :active-value="true" :inactive-value="false"></el-switch>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="Edit Schedule" :visible.sync="dialogVisible" @close="closeFormDialog">
            <el-form :model="scheduleForm" ref="scheduleForm" label-width="120px">
                <el-form-item label="Name" prop="name">
                    <el-input v-model="scheduleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="Function Name" prop="func_name">
                    <el-input v-model="scheduleForm.func_name"></el-input>
                </el-form-item>
                <el-form-item label="Args">
                    <el-form-item v-for="(item, index) in scheduleForm.args" :key="index" label="Arg"
                        :prop="'args.' + index">
                        <el-input :value="item" @input="updateArgument(index, $event)"></el-input>
                        <el-button type="text" @click="removeArgument(index)">Remove</el-button>
                    </el-form-item>
                    <el-button type="text" @click="addArgument">Add Argument</el-button>
                </el-form-item>
                <!-- <el-form-item label="KArgs" prop="kwargs">
                    <el-input v-model="scheduleForm.kwargs" placeholder="Plz input json"></el-input>
                    {{ kwargsStr }}
                </el-form-item> -->
                <el-form-item label="Trigger Type" prop="trigger_type">
                    <el-select v-model="scheduleForm.trigger_type" placeholder="Select">
                        <el-option v-for="item in trigger_type" :key="item" :label="item.label" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Trigger" prop="trigger">
                    <div v-if="scheduleForm.trigger_type == 'cron'">
                        <el-form-item label="Year">
                            <el-input v-model="scheduleForm.trigger.year"></el-input>
                        </el-form-item>
                        <el-form-item label="Month">
                            <el-input v-model="scheduleForm.trigger.month"></el-input>
                        </el-form-item>
                        <el-form-item label="Week">
                            <el-input v-model="scheduleForm.trigger.week"></el-input>
                            ISO week (1-53)
                        </el-form-item>
                        <el-form-item label="Day Of Month">
                            <el-input v-model="scheduleForm.trigger.day"></el-input>
                            day of month (1-31)
                        </el-form-item>
                        <el-form-item label="Day Of Week">
                            <el-input v-model="scheduleForm.trigger.day_of_week"></el-input>
                            number or name of weekday (0-6 or mon,tue,wed,thu,fri,sat,sun)
                        </el-form-item>
                        <el-form-item label="hour">
                            <el-input v-model="scheduleForm.trigger.hour"></el-input>
                        </el-form-item>
                        <el-form-item label="Minute">
                            <el-input v-model="scheduleForm.trigger.minute"></el-input>
                        </el-form-item>
                        <el-form-item label="Second">
                            <el-input v-model="scheduleForm.trigger.second"></el-input>
                        </el-form-item>
                    </div>
                    <div v-else>
                        <el-form-item label="Weeks">
                            <el-input-number v-model="scheduleForm.trigger.weeks"></el-input-number>
                        </el-form-item>
                        <el-form-item label="Days">
                            <el-input-number v-model="scheduleForm.trigger.days"></el-input-number>
                        </el-form-item>
                        <el-form-item label="hours">
                            <el-input-number v-model="scheduleForm.trigger.hours"></el-input-number>
                        </el-form-item>
                        <el-form-item label="Minutes">
                            <el-input-number v-model="scheduleForm.trigger.minutes"></el-input-number>
                        </el-form-item>
                        <el-form-item label="Seconds">
                            <el-input-number v-model="scheduleForm.trigger.seconds"></el-input-number>
                        </el-form-item>
                    </div>
                    <el-form-item label="Start Date">
                        <el-date-picker v-model="scheduleForm.trigger.start_date">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="End Date">
                        <el-date-picker v-model="scheduleForm.trigger.end_date">
                        </el-date-picker>
                    </el-form-item>
                </el-form-item>
                <el-form-item label="Active" prop="active">
                    <el-switch v-model="scheduleForm.active"></el-switch>
                </el-form-item>
                <el-form-item label="E-Mail" prop="email_flag">
                    <el-switch v-model="scheduleForm.email_flag"></el-switch>
                </el-form-item>
                <el-form-item v-show="scheduleForm.email_flag" label="Subject" prop="subject">
                    <el-input type="textarea" autosize v-model="scheduleForm.subject"></el-input>
                </el-form-item>
                <el-form-item v-show="scheduleForm.email_flag" label="Content" prop="content">
                    <el-input type="textarea" autosize v-model="scheduleForm.content"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm">Submit</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { post, get } from '../../api'

export default {
    // computed: {
    //     kwargsStr() {
    //         return JSON.stringify(this.scheduleForm.kwargs)
    //     }
    // },
    data() {
        return {
            dialogVisible: false,
            loading: true,
            multipleSelection: [],
            scheduleList: [],
            scheduleForm: {
                name: '',
                func_name: '',
                args: [],
                kwargs: {},
                trigger_type: 'cron',
                trigger: {},
                active: false,
                email_flag: false,
                subject: '',
                content: '',
            },
            running: false,
            trigger_type: ['cron', 'interval']
        };
    },
    created() {
        this.reflush()
    },
    methods: {
        reflush() {
            this.loadScheduleList()
            this.getScheduleStatus()
        },
        startScheduler() {
            get('/schedule/start').then(() => {
                this.$message.success('Start successful!');
                this.reflush()
            })
        },
        stopScheduler() {
            get('/schedule/stop').then(() => {
                this.$message.success('Stop successful!');
                this.reflush()
            })
        },
        loadScheduleList() {
            this.loading = true
            get('/schedule/list').then(res => {
                this.scheduleList = res.data
                this.loading = false
            })
        },
        getScheduleStatus() {
            get('/schedule/running').then(res => {
                this.running = res.data
            })
        },
        editSchedule(formData) {
            this.scheduleForm = formData;
            this.dialogVisible = true;
        },
        executeTask(id) {
            this.$confirm('Are you sure you want to execute the selected task?', 'Warning', {
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                type: 'warning'
            })
                .then(() => {
                    post('/schedule/execute', {"id": id})
                        .then(() => {
                            this.$message.success('Execute successful!');
                            this.loadScheduleList();
                        })
                })
                .catch(() => {
                    this.$message.info('Execute canceled');
                });
        },
        showDialog() {
            this.dialogVisible = true;
        },
        closeFormDialog() {
            this.dialogVisible = false;
            this.scheduleForm = {
                name: '',
                func_name: '',
                args: [],
                kwargs: {},
                trigger_type: 'cron',
                trigger: {},
                active: false,
                email_flag: false,
                subject: '',
                content: '',
            }
        },
        handleSelectionChange(selection) {
            this.multipleSelection = selection;
        },
        submitForm() {
            // this.scheduleForm.kwargs = JSON.parse(this.scheduleForm.kwargs)
            post('/schedule/save', this.scheduleForm).then(() => {
                this.dialogVisible = false;
                this.$message.success('Save successful!');
                this.reflush()
            })
        },
        handleDelete() {
            this.$confirm('Are you sure you want to delete the selected items?', 'Warning', {
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                type: 'warning'
            })
                .then(() => {
                    const selectedIds = this.multipleSelection.map(item => item.id);
                    post('/schedule/delete', selectedIds)
                        .then(() => {
                            this.$message.success('Delete successful!');
                            this.loadScheduleList();
                        })
                        .catch(() => {
                            this.$message.error('Delete failed!');
                        });
                })
                .catch(() => {
                    this.$message.info('Delete canceled');
                });
        },
        addArgument() {
            this.scheduleForm.args.push('');
        },
        updateArgument(index, value) {
            this.$set(this.scheduleForm.args, index, value);
        },
        removeArgument(index) {
            this.scheduleForm.args.splice(index, 1);
        }
    }
};
</script>

<style scoped>
.schedule {
    margin-top: 1%;
}
</style>
