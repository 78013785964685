<template>
    <div class="login-container">
        <el-form ref="form" :model="form" class="login-form">
            <el-form-item label="登录名">
                <el-input v-model="form.username"></el-input>
            </el-form-item>
            <el-form-item label="密码">
                <el-input type="password" v-model="form.password"></el-input>
            </el-form-item>
            <el-form-item label="确认密码">
                <el-input type="password" v-model="form.password"></el-input>
            </el-form-item>
            <el-form-item label="邮件">
                <el-input v-model="form.email"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onRegister">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
  
<script>
import md5 from 'js-md5';
import { post } from '../api.js';

export default {
    name: "Register",
    data() {
        return {
            form: {
                username: '',
                password: '',
                email: ''
            },
            salt: 'TaocoWebInvestBlog'
        }
    },
    methods: {
        verifyMail() {

        },
        onRegister() {
            this.form.password = md5(this.salt + this.form.password)
            post('/user/register', this.form).then(res => {
                if (res.success == true) {
                    localStorage.setItem('token', res.data.access_token)
                    this.$router.push('/')
                } else {
                    this.$message.error(res.message);
                }
            });
        }
    }
}
</script>

<style scoped>
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.login-form {
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>