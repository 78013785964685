<template>
  <div>
    <el-upload :action="musicUploadUrl" :on-success="handleUploadSuccess" :show-file-list="false" :headers="headerObj"
               multiple>
      <el-button type="primary">Upload</el-button>
    </el-upload>
    <el-table :data="musicList" v-loading="loading" element-loading-text="loading...">
      <el-table-column label="Setting">
        <template slot-scope="scope">
          <el-button size="mini" icon="el-icon-download" @click="downloadImage(scope.row)"/>
          <el-button size="mini" icon="el-icon-close" @click="deleteImage(scope.row.filename)"/>
          <el-button size="mini" icon="el-icon-upload2" @click="toUploadLrc(scope.row)"/>
          <div v-show="scope.row.isUploadLrc">
            <el-input style="width: 31%;" placeholder="url"
                      v-model="scope.row.lrcUrl"></el-input>
            <el-button size="mini" icon="el-icon-upload2" style="margin-left: 2%" @click="uploadLrc(scope.row)"/>
            <el-button size="mini" icon="el-icon-magic-stick" style="margin-left: 2%"
                       @click="toSourceWeb(scope.row)"></el-button>
            <el-upload :action="lrcUploadUrl"
                       :on-success="handleUploadLrcSuccess"
                       :show-file-list="false"
                       :data="{
                       music: JSON.stringify(scope.row)
                     }"
                       :headers="headerObj">
              <el-button size="mini"
                         :icon="scope.row.info.lrc ? 'el-icon-refresh' : 'el-icon-upload2'"/>
            </el-upload>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Title" prop="info.title" min-width="50%"/>
      <el-table-column label="Artist" prop="info.artist" min-width="26%"/>
    </el-table>
  </div>
</template>

<script>
import {post} from '@/api'
import FileSaver from 'file-saver';

export default {
  name: 'MusicList',
  data() {
    return {
      loading: true,
      music_id: null,
      musicList: [],
      headerObj: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    };
  },
  computed: {
    musicUploadUrl() {
      return 'http://taocoweb.top/api/music/upload';
    },
    lrcUploadUrl() {
      return 'http://taocoweb.top/api/music/uploadLrc';
    }
  },
  created() {
    this.music_id = this.$route.params.id
    this.getList()
  },
  methods: {
    getList() {
      let payload = {'file_type': 'audio'}
      if (this.music_id) {
        payload['id'] = this.music_id
      }
      post('/file/list', payload).then(res => {
        // 为每个音乐项添加独立的状态
        this.musicList = res.data.map(item => ({
          ...item,
          isUploadLrc: false,
          lrcUrl: this.music_id || !item.info.lrc ? item.info.name : ''
        }));
        this.loading = false
      })
    },
    toSourceWeb(row) {
      window.open('https://tools.liumingye.cn/music/#/search/M/song/' + row.lrcUrl, '_blank')
      row.lrcUrl = ''
    },
    toUploadLrc(row) {
      row.isUploadLrc = !row.isUploadLrc
      // 关闭其他行的isUploadLrc
      this.musicList.forEach(item => {
        if (item !== row) {
          item.isUploadLrc = false
        }
      })
    },
    uploadLrc(row) {
      if (!row.lrcUrl) {
        this.$message.error('url不能为空！');
        return;
      }
      const payload = {
        ...row,
        lrc_url: row.lrcUrl
      }
      post('/music/onlineLrc', payload).then(() => {
        this.$message.success('歌词加载成功');
        if (this.music_id) {
          this.$router.push('/music');
        }
      })
    },
    handleUploadSuccess(response) {
      if (response.success) {
        this.$message.success('音乐上传成功');
        // 为新上传的音乐项添加独立状态
        this.musicList.push({
          ...response.data,
          isUploadLrc: false,
          lrcUrl: ''
        });
      } else {
        this.$message.error('音乐上传失败');
      }
    },
    handleUploadLrcSuccess(response) {
      if (response.success) {
        this.$message.success('歌词上传成功');
        if (this.music_id) {
          this.$router.push('/music');
        }
      } else {
        this.$message.error('歌词上传失败');
      }
    },
    downloadImage(music) {
      fetch(music.url)
          .then(response => response.blob())
          .then(blob => {
            FileSaver.saveAs(blob, music.filename);
          })
          .catch(() => {
            this.$message.error('下载失败');
          });
    },
    deleteImage(image_name) {
      this.$confirm('Are you sure you want to delete the selected items?', 'Warning', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
          .then(() => {
            post('/file/delete', {'filename': image_name}).then(res => {
              if (res.success) {
                this.$message.success('Deleted!');
                this.getList()
              }
            })
          })
          .catch(() => {
            this.$message.info('Delete canceled');
          });
    }
  },
};
</script>

<style></style>
