<template>
    <el-dialog :visible.sync="visible">
        <div class="image-grid">
            <div v-for="image in imageList" :key="image.id" class="image-item">
                <img :src="image.url" @click="choose(image.url)" />
            </div>
        </div>
    </el-dialog>
</template>
  
<script>
import { post } from '@/api'

export default {
    name: 'ImageSelectDialog',
    data() {
        return {
            visible: false,
            imageList: []
        }
    },
    methods: {
        open() {
            this.visible = true;
            post('/file/list', {'file_type': 'image'}).then(res => {
                this.imageList = res.data;
            })
        },
        close() {
            this.visible = false;
        },
        choose(url) {
            this.$parent.blog.cover_image_name = url
            this.close()
        }
    }
}
</script>
  
<style scoped>
.image-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.image-item {
    width: calc(25% - 10px);
    margin-bottom: 10px;
}

.image-item img {
    width: 100%;
    cursor: pointer;
}
</style>