<template>
    <el-dialog v-if="visible" :title="explanation_info" :visible.sync="visible" modal :close-on-click-modal="false"
        @close="close">
        <div v-for="(example, index) in examps" :key="index">
            <div>{{ example.en }}</div>
            <div>{{ example.zh }}</div>
            <hr />
        </div>
    </el-dialog>
</template>
  
<script>
export default {
    data() {
        return {
            visible: false,
            explanation_info: '',
            examps: []
        };
    },
    methods: {
        open(explanation_info, examps) {
            this.visible = true;
            this.explanation_info = explanation_info
            this.examps = examps
        },
        close() {
            this.visible = false;
            this.examps = {};
        },
    }
};
</script>
  
<style scoped></style>
