<template>
  <div>
    <div class="music-player">
      <el-table v-loading="loading"
                element-loading-text="loading..."
                :data="songList"
                @row-click="playSong"
                :row-class-name="playSongClassName">
        <el-table-column label="Name">
          <template slot-scope="scope">
            {{ scope.row.info.title }}
            <el-tag effect="plain" size="mini" v-show="scope.row.info.lrc">词</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div class="audio-player" @click="toggleLyricPanel" :class="{ 'show-lyrics': showLyricsPanel }">
        <div class="progress-bar">
          <span>{{ formatTime(currentTime) }}</span>
          <el-slider
              v-model="sliderValue"
              :max="duration"
              @change="onSliderChange"
              :format-tooltip="formatTooltip"
              :show-tooltip="false"
          ></el-slider>
          <span>{{ formatTime(duration) }}</span>
        </div>
        <div class="controls">
          <el-button icon="el-icon-caret-left" @click.stop="prevSong" circle/>
          <el-button :icon="isPlaying ? 'el-icon-video-pause': 'el-icon-video-play'" @click.stop="togglePlay" circle/>
          <el-button icon="el-icon-caret-right" @click.stop="nextSong" circle/>
          <el-button :icon="playModeIcon" @click.stop="togglePlayMode" circle></el-button>
        </div>

        <!-- 歌词展示区域 -->
        <div class="lyrics-panel" v-if="showLyricsPanel">
          <div v-if="parsedLyrics.length" class="lyrics" ref="lyricsPanel">
            <p v-for="(line, index) in parsedLyrics" :key="index"
               :class="{ 'active-lyric': currentLyricIndex === index }">
              {{ line.text }}
            </p>
          </div>
          <div v-else class="lyrics" ref="lyricsPanel">
            <p class="active-lyric">暂无歌词</p>
            <p><a :href="'/user/dashboard/music-list/'+songList[currentIndex].id">添加歌词</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {post} from '@/api'

export default {
  name: 'Music',
  data() {
    return {
      loading: true,
      audio: new Audio(),
      songList: [],
      currentIndex: null,
      isPlaying: false,
      playMode: 0, // 0: 列表循环, 1: 单曲循环, 2: 随机播放
      currentTime: 0,
      duration: 0,
      sliderValue: 0,
      showLyricsPanel: false,
      parsedLyrics: [], // 解析后的歌词
      currentLyricIndex: 0, // 当前歌词的索引
    }
  },
  mounted() {
    // 添加音频结束事件监听器
    this.audio.addEventListener('ended', this.onAudioEnded);
    this.audio.addEventListener('timeupdate', this.onTimeUpdate);
    this.audio.addEventListener('loadedmetadata', this.onLoadedMetadata);
    window.addEventListener('beforeunload', this.saveStateToLocalStorage);

    // 添加媒体键事件监听器
    navigator.mediaSession.setActionHandler('play', () => this.togglePlay());
    navigator.mediaSession.setActionHandler('pause', () => this.togglePlay());
    navigator.mediaSession.setActionHandler('previoustrack', () => this.prevSong());
    navigator.mediaSession.setActionHandler('nexttrack', () => this.nextSong());
  },
  beforeDestroy() {
    // 移除音频结束事件监听器
    this.audio.removeEventListener('ended', this.onAudioEnded);
    this.audio.removeEventListener('timeupdate', this.onTimeUpdate);
    this.audio.removeEventListener('loadedmetadata', this.onLoadedMetadata);
    window.removeEventListener('beforeunload', this.saveStateToLocalStorage);
    this.saveStateToLocalStorage();

    // 移除媒体键事件监听器
    navigator.mediaSession.setActionHandler('play', null);
    navigator.mediaSession.setActionHandler('pause', null);
    navigator.mediaSession.setActionHandler('previoustrack', null);
    navigator.mediaSession.setActionHandler('nexttrack', null);
  },
  created() {
    this.loadSongList()
  },
  watch: {
    currentIndex() {
      this.saveStateToLocalStorage();
    },
    isPlaying() {
      this.saveStateToLocalStorage();
    },
    playMode() {
      this.saveStateToLocalStorage();
    },
  },
  computed: {
    playModeIcon() {
      switch (this.playMode) {
        case 0:
          return 'el-icon-s-unfold';
        case 1:
          return 'el-icon-refresh';
        case 2:
          return 'el-icon-sort';
        default:
          return 'el-icon-s-unfold';
      }
    },
  },
  methods: {
    loadSongList() {
      post('/file/list', {'file_type': 'audio'}).then(res => {
        this.songList = res.data;
        this.loading = false;
        this.loadStateFromLocalStorage();
      })
    },
    playSong(song) {
      this.currentIndex = this.songList.indexOf(song);
      this.audio.src = song.url;
      this.audio.play();
      this.isPlaying = true;
      if (song.info.lrc) {
        this.parseLyrics(song.info.lrc);
      } else {
        this.parsedLyrics = []
      }

      // 更新媒体会话元数据ß
      if ('mediaSession' in navigator) {
        // eslint-disable-next-line no-undef
        navigator.mediaSession.metadata = new MediaMetadata(song.info)
      }
    },
    prevSong() {
      this.currentIndex = (this.currentIndex - 1 + this.songList.length) % this.songList.length;
      this.playSong(this.songList[this.currentIndex]);
    },
    nextSong() {
      switch (this.playMode) {
        case 0: // 列表循环
          this.currentIndex = (this.currentIndex + 1) % this.songList.length;
          break;
        case 1: // 单曲循环
          break; // 保持当前歌曲索引不变
        case 2: // 随机播放
          this.currentIndex = Math.floor(Math.random() * this.songList.length);
          break;
      }
      this.playSong(this.songList[this.currentIndex]);
    },
    togglePlay() {
      if (this.isPlaying) {
        this.audio.pause();
      } else {
        this.audio.play();
      }
      this.isPlaying = !this.isPlaying;

      // 更新媒体会话播放状态
      if ('mediaSession' in navigator) {
        navigator.mediaSession.playbackState = this.isPlaying ? 'playing' : 'paused';
      }
    },
    playSongClassName({rowIndex}) {
      // 如果当前行是选中的行，返回一个特殊的类名
      if (rowIndex === this.currentIndex) {
        return 'selected-row';
      }
      return '';
    },
    togglePlayMode() {
      this.playMode = (this.playMode + 1) % 3;
    },
    onAudioEnded() {
      this.nextSong()
    },
    onTimeUpdate() {
      this.currentTime = this.audio.currentTime;
      this.sliderValue = this.currentTime;
      this.updateLyrics();
    },

    onLoadedMetadata() {
      this.duration = this.audio.duration;
    },

    onSliderChange(value) {
      this.audio.currentTime = value;
    },

    formatTime(time) {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },

    formatTooltip(value) {
      return this.formatTime(value);
    },
    saveStateToLocalStorage() {
      const state = {
        currentIndex: this.currentIndex,
        isPlaying: this.isPlaying,
        playMode: this.playMode,
        currentTime: this.audio.currentTime,
        parsedLyrics: this.parsedLyrics,
        currentLyricIndex: this.currentLyricIndex,
      };
      localStorage.setItem('musicPlayerState', JSON.stringify(state));
    },
    loadStateFromLocalStorage() {
      const savedState = localStorage.getItem('musicPlayerState');
      if (savedState) {
        const state = JSON.parse(savedState);
        this.currentIndex = state.currentIndex;
        this.isPlaying = state.isPlaying;
        this.playMode = state.playMode;
        this.currentTime = state.currentTime;
        this.parsedLyrics = state.parsedLyrics;
        this.currentLyricIndex = state.currentLyricIndex;

        // 恢复音频状态
        if (this.currentIndex !== null) {
          this.audio.src = this.songList[this.currentIndex].url;
          this.audio.currentTime = state.currentTime;
          this.togglePlay()
        }
      }
    },
    toggleLyricPanel() {
      this.showLyricsPanel = !this.showLyricsPanel;
    },
    parseLyrics(lrc) {
      const lines = lrc.split('\n'); // 按行分割歌词
      const parsed = [];
      const timePattern = /\[(\d{2}):(\d{2})\.(\d{2,3})\]/; // 正则匹配[mm:ss.sss]格式

      lines.forEach((line) => {
        const match = line.match(timePattern); // 查找时间戳
        if (match) {
          const minutes = parseInt(match[1], 10); // 提取分钟
          const seconds = parseInt(match[2], 10); // 提取秒
          const milliseconds = parseInt(match[3], 10); // 提取毫秒
          const time = minutes * 60 + seconds + milliseconds / 1000; // 转换为总时间（秒）
          const text = line.replace(timePattern, '').trim(); // 去掉时间戳后的歌词内容
          parsed.push({time, text});
        }
      });
      this.parsedLyrics = parsed; // 存储解析后的歌词
    },
    updateLyrics() {
      // 查找当前时间下的歌词
      for (let i = 0; i < this.parsedLyrics.length; i++) {
        if (this.audio.currentTime < this.parsedLyrics[i].time) {
          this.currentLyricIndex = i - 1; // 当前歌词索引为前一行
          this.scrollLyricsToCurrent(); // 滚动歌词到当前
          break;
        }
      }
    },
    scrollLyricsToCurrent() {
      if (this.$refs.lyricsPanel) {
        const panel = this.$refs.lyricsPanel;
        const activeLine = panel.querySelector('.active-lyric'); // 查找当前歌词
        if (activeLine) {
          panel.scrollTop = activeLine.offsetTop - panel.clientHeight / 2; // 滚动至当前歌词
        }
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.saveStateToLocalStorage();
    this.togglePlay()
    next()
  }
};
</script>

<style>
.audio-player {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 75%;
  transition: height 0.3s ease;
  height: 85px; /* 默认高度 */
}

.audio-player.show-lyrics {
  height: 80%; /* 展开后高度 */
}

.lyrics-panel {
  overflow-y: scroll;
  height: 87%;
  margin-top: 10px;
  position: relative;
}

/* 隐藏滚动条 */
.lyrics-panel::-webkit-scrollbar {
  width: 0; /* 不显示滚动条 */
}

.lyrics p {
  text-align: center;
  margin: 5px 0;
  line-height: 2; /* 增加行高使歌词间距更大 */
  transition: all 0.3s ease; /* 添加过渡效果 */
}

.active-lyric {
  font-weight: bold;
  font-size: x-large;
  color: rgb(43, 109, 216);
  /*transform: scale(1.1); !* 当前歌词稍微放大 *!*/
}

.selected-row {
  color: rgb(43, 109, 216); /* 你也可以更改字体颜色，使之与背景色对比鲜明 */
  font-weight: bold;
}

.progress-bar {
  display: flex;
  align-items: center;
}

.progress-bar .el-slider {
  margin: 0 10px;
  flex-grow: 1;
}

.controls {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
</style>
